.form {
  max-width: 600px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.row_space_around {
  display: flex;
  gap: 10px;
  justify-content: space-around;
}
